a.social-link.active {
    background: linear-gradient( 
121.85deg
 , #ef4443 14.84%, #ef6b18 91.11%);
    color: white;
}

.block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .button-group-cb-div .btn.btn-primary-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 145px;
}

.block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .button-group-cb-div .btn.btn-common-primary.btn-book-now-fill {
    font-size: 16px;
    padding: 8px 35px 6px 35px;
    min-width: 145px;
}
.ins-pro-banner-section .instructor-profile-bx-new .ip-creators-content-div h3 .txt-span {
    width: calc(100% - 40px) !important;
    margin-left: 10px;
}
.block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .button-group-cb-div .btn.mr-16 {
    margin-right: 16px !important;
}

.block-banner-new-classes-div.pattern-top-01:before {
    content: "";
    display: inline-block;
    background: url(../images/shape-banner-2.svg) no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.block-banner-new-classes-div.pattern-top-02:before {
    content: "";
    display: inline-block;
    background: url(../images/shape-banner.svg) no-repeat;
    background-size: cover;
    background-position: top;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

body header .header-div.header-bgcolor {
    background-color: #111117;
}

body header .header-div2.header-color-2 {
    background-color: #111117;
}

body .user-main-banner-section .user-main-banner-div .heading-div .filter-category-root-div .filter-category-inner .filter-list-ul li .filter-link:focus {
    border-color: #282a33;
}

body .user-main-banner-section .user-main-banner-div .heading-div .filter-category-root-div .filter-category-inner .filter-list-ul li.active .filter-link:focus {
    border-color: #ef6b18;
}

.general-chat-section .container-general-video-div .general-video-root .general-video-content .general-video-bottom-fixed {
    -webkit-transition: width 0.8s;
    transition: width 0.8s;
}

.general-chat-section .container-general-video-div.active .general-video-root .general-video-content .general-video-bottom-fixed {
    width: calc(100% - 510px);    
}
i.material-icons-outlined.refresh-custom-icon {
    color: #808191;
    font-size: 26px;
}
.block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .terms-div p{
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    line-height: 1.5;
}
.block-banner-new-classes-section .block-banner-new-classes-div .padding-top-130{
padding-top:130px;
}
.block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .terms-div.pt-55
{
    padding-top: 55px;
}
.testimonials-slider-slider-root .testimonial-info{
    padding-left: 40px;
}

body .testimonials-section .testimonials-div .heading-div h2 {
    padding: 0;
    font-size: 50px;
    text-align: center;
}

body .our-pricing-plan-card .flex-plan-card-inner .min-details-div .fp-title-top {
    justify-content: center;
}

body .our-pricing-plan-section {
    padding: 90px 0 40px 0;
}
.general-book-course-div .heading-div .new-subtitle
{
    text-align: center;
    color: #fff;

}

@media (min-width: 1025px) {
    section.block-banner-new-classes-section.block-banner-classes-02 {
        top: -140px;
        margin-bottom: -40px;
    }
    section.block-banner-new-classes-section.block-banner-classes-01 {
        top: -120px;
        margin-bottom: -220px;
    }
    section.block-banner-new-classes-section.block-banner-classes-01 img.img.img-responsive {
        width: 140%;
        margin-left: -150px;
    }
    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right h3 {
        max-width: 225px !important;
    }
    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right .text-desc .time-span {
        max-width: 80px !important;
        word-break: break-word;
        text-align: right;
        width: 100%;
    }
    body .main-banner-section .banner-div {
        min-height: 750px;
    }
    body .block-banner-new-classes-section .block-banner-new-classes-div .center-content-div h2 {
        font-size: 50px;
    }
    body .block-banner-new-classes-div.pattern-top-01:before {
        top: -10px;
    }
    body .oto-sessions-section .heading-div h2 {
        text-align: left;
    }
}

@media (max-width: 767px) {

    .general-payment-section .general-payment-div .general-bottom .general-btn-div-row.custom-payment-btn .btn.btn-primary-outline {
        min-width: 255px;
        min-height: 57px;
        margin-bottom: 10px;
        
    }
    .general-payment-section .general-payment-div .general-bottom .general-btn-div-row.custom-payment-btn .btn-common-primary {
      min-width: 255px;
      min-height: 57px;
      margin-bottom: 10px;
     
    }
    .general-btn-div-row.custom-payment-btn .general-btn-div-right .custom-paypal-btn #zoid-paypal-buttons-uid_06c59ab36f_mdy6mtm6nty {
      min-height: 71px; 
      min-width: 264px;
      max-width: 750px ;
      margin-bottom: 10px;
    }
    .general-bottom .custom-payment-btn #custom-paypal-button.custom-paypal-btn a div div.paypal-buttons{
      min-height: 71px ; 
      min-width: 264px ;
      max-width: 750px ;
    }

    section.block-banner-new-classes-section.block-banner-classes-01 img.img-responsive {
        margin-bottom: -40px;
        transform: scale(1.3);
    }
    section.block-banner-new-classes-section.block-banner-classes-01  {
      overflow-x: hidden;
    }
    .our-classes-section .our-classes-div.pb-0 {
        padding-bottom: 61px !important;
    }
    .our-classes-section .our-classes-div.pb-0 .owl-theme .owl-dots .owl-dot span {
        opacity: 0;
    }
    .block-banner-new-classes-div.pattern-top-01:before, .block-banner-new-classes-div.pattern-top-02:before {
        display: none;
    }

    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right h3 {
        max-width: 125px !important;
    }

    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right .text-desc .time-span {
        word-break: break-word;
        text-align: right;
    }
    
    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right .text-desc {
        text-align: left;
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box {
        height: calc(100vh - 115px);
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box .card-footer .card-footer-row .card-form textarea.form-control {
        padding: 17px 25px 15px 30px;
        height: 60px;
        font-size: 14px;
    }

    .general-chat-section .container-general-video-div .general-video-root .general-video-content .general-video-bottom-fixed .white-icon-text-list .white-icon-text-list-row .white-icon-text-box .btn.btn-common-primary {
        padding: 0 15px;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 25px;
    }

    .general-chat-section .container-general-video-div .general-video-root .general-video-content .general-video-bottom-fixed .white-icon-text-list .white-icon-text-list-row .white-icon-text-box .btn.btn-common-primary .mb-hidden {
        display: none;
    }
    .general-chat-section .container-general-video-div .general-video-root .general-video-content .general-video-bottom-fixed {
        display: block;
    }
    .general-chat-section .container-general-video-div.active .general-video-root .general-video-content .general-video-bottom-fixed {
        display: none;
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-progress-group-header {
        min-height: 106px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box {
        min-height: calc(100vh - 60px);
        padding-top: 106px;
        height: calc((var(--vh, 1vh) * 100) - 70px);
        height: -webkit-fill-available;
        padding-bottom: 10px;
    
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box .card-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 9999;
        background-color: #111117;
    }

    body .participent-list-main {
        height: 100vh;
        padding-top: 106px;
    }
    .block-banner-new-classes-section .block-banner-new-classes-div .padding-top-130 {
        padding-top: 0px;
    }
    body .testimonials-section .testimonials-div .heading-div h2 {
        font-size: 24px;
    }
}
.tabs-root-common .tabs-header-common .nav-tabs .nav-itemProfile {
    width: 100%;
    margin-bottom: 0;
  }
@media (min-width: 768px) and (max-width: 1024px) {
    .block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .button-group-cb-div .btn.btn-primary-outline {
        min-width: 115px;
    }
    section.block-banner-new-classes-section.block-banner-classes-01 img.img-responsive {
        margin-right: 0px;
    transform: scale(1.5);
    object-fit: contain;
    width: 100%
    }
    .block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .button-group-cb-div .btn.btn-common-primary.btn-book-now-fill {
        min-width: 115px;
    }

    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right h3 {
        max-width: 125px !important;
    }

    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right .text-desc .time-span {
        word-break: break-word;
        text-align: right;
    }

    .dmenu-common-nt-root .nts-dmenu-common-div .nts-dmenu-common-inner .nts-dmenu-listing-div .nts-dmenu-box .nts-dmenu-row .nt-right .text-desc {
        text-align: left;
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box {
        height: calc(100vh - 115px);
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box .card-footer .card-footer-row .card-form textarea.form-control {
        padding: 17px 25px 15px 30px;
        height: 60px;
        font-size: 14px;
    }
    .general-chat-section .container-general-video-div.active .general-video-root .general-video-content .general-video-bottom-fixed {
        width: calc(100% - 290px);
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-progress-group-header {
        min-height: 106px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box {

        min-height: calc(100vh - 60px);
        padding-top: 106px;
        height: calc((var(--vh, 1vh) * 100) - 70px);
        height: -webkit-fill-available;
        padding-bottom: 10px;
    }

    body .general-chat-section .chatting-general-div .chat-progress-group-inner .chat-area-box-root .chat-box .card-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 9999;
        background-color: #111117;
    }

    body .participent-list-main {
        height: 100vh;
        padding-top: 106px;
    }
    .block-banner-new-classes-section .block-banner-new-classes-div .padding-top-130 {
        padding-top: 0px;
    }
    .block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .terms-div p {
       
        font-size: 10px;
    }
    .block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .terms-div.pt-55 {
        padding-top: 25px;
    }
    .block-banner-new-classes-section .block-banner-new-classes-div .center-content-div .desc-div p {
        
        font-size: 14px;
        line-height: 20px;
       
    }
    body .oto-sessions-section .heading-div h2 {
        text-align: left;
    }
}
.btnreqslots {
    background-color: #ef4443;
    background-image: linear-gradient(
131deg, #ef4443 4%, #ef6b18 100%);
    border: none;
    border-radius: 100px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    letter-spacing: 0;
    min-height: 50px;
    padding: 6px 30px 5px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.8s;
    width: 205px;
}
.profilEmail{
    color:white;
    font-size: 16px;
    font-weight: bold;
}
